import * as api from '../utils/api';


export const counters = {
    namespaced: true,
    state: {
        settings: {},
        plots: {},
        longPlots: {},
        algorithms: {}
    },
    actions: {
        load({ commit, dispatch, state }, {homeId, force = false}) {
            if (force || Object.keys(state.algorithms).length === 0){
                api.counterAlgorithms().then((resp) => {
                    commit('setAlgorithms', resp.algorithms);
                });
            }
            if (force || state.settings[homeId] === undefined){
                api.countersSettings(homeId).then((resp) => {
                    commit('setSettings', {homeId, settings: resp.settings});
                });
            }
            if (force || state.plots[homeId] === undefined){
                api.counterPlots(homeId).then((resp) => {
                    if (Object.values(resp.counters).reduce((acc, x)=>acc + x.length, 0) < 30){
                        dispatch('loadLongPlots', {homeId});
                    }
                    commit('setPlots', {homeId, plots: resp.counters});
                });
            }
        },
        loadLongPlots({ commit, state }, {homeId, force = false}) {
            if (force || state.longPlots[homeId] === undefined){
                api.counterPlots(homeId, 365).then((resp) => {
                    commit('setLongPlots', {homeId, plots: resp.counters});
                });
            }
        },
        changeDisplay({commit, state}, {homeId, counterId, data}) {
            const settings = state.settings[homeId];
            if (!settings.display)
                settings.display = {};
            const base = settings.display[counterId] || {};
            settings.display[counterId] = {...base, ...data};
            api.setCountersSettings(homeId, settings).then(() => {
                commit('setSettings', {homeId, settings});
            });
        },
        change({commit, state}, {homeId, data}) {
            const settings = state.settings[homeId];
            const set_ = (param) => {
                if (data[param])
                    settings[param] = Object.assign({}, settings[param] || {}, data[param]);
            };
            set_('send_config');
            set_('send_every');
            set_('scan_settings');
            
            api.setCountersSettings(homeId, settings).then(() => {
                commit('setSettings', {homeId, settings});
            });
        }
    },
    mutations: {
        setSettings(state, {homeId, settings}) {
            state.settings[homeId] = settings;
        },
        setAlgorithms(state, algorithms) {
            state.algorithms = algorithms;
        },
        setPlots(state, {homeId, plots}) {
            state.plots[homeId] = plots;
        },
        setLongPlots(state, {homeId, plots}) {
            state.longPlots[homeId] = plots;
        },
    },
    getters: {
        settings(state) {
            return (homeId) => state.settings[homeId];
        },
        plots(state) {
            return (homeId) => state.plots[homeId];
        },
        longPlots(state) {
            return (homeId) => state.longPlots[homeId];
        },
        algorithms(state) {
            return state.algorithms;
        }
    }
};