import * as api from '../../utils/api';
import {humanAgo} from '../../utils/string';
import {ref} from 'vue';


const NO_HUBS_STATUS = {text: 'no hubs', state: 0, disable: true};


export const hubStatus = (homeId) => {
    const data = {
        resp: null,
        interval: null,
        nextRefresh: null,
        lastConn: null,
        suffix: '',
        updateOnMinutes: 1.0
    };

    const statusRef = ref(NO_HUBS_STATUS);

    const getDeviceStatus = () => {
        const timestamp = data.lastConn;
        if (timestamp === null)
            return NO_HUBS_STATUS;
        if (data.nextRefresh === null)
            data.nextRefresh = timestamp + 1000 * 60 * data.updateOnMinutes;
        const diff = (Date.now() - timestamp)/1000;
        const text = humanAgo(diff * 1000);
        const state = diff < 600 ? 1 : diff < 3600 ? 2 : 3;
        return {text, state};
    };
    
    const processHubs = () => {
        if (data.resp && data.resp.length){
            let lastConn = data.resp[0].last_http;
            data.suffix = '';
            if (data.resp.length > 1){
                const conns = data.resp.map(h=>h.last_http);
                conns.sort();
                lastConn = conns[0];
                data.suffix = ' (longest)';
            }
            data.lastConn = +(new Date(lastConn));
        } 
    };

    const refresh = async () => {
        const resp = await api.home(homeId);
        data.nextRefresh = null;
        data.resp = resp.home.hubs;
        processHubs();
        return resp;
    };

    const runWatch = async (dt) => {
        const update = () => {
            if (!document.hidden){
                const status = getDeviceStatus();
                status.text += data.suffix;
                statusRef.value = status;
                if (status.disable)
                    clearInterval(data.interval);
                const now = Date.now();
                if (data.nextRefresh !== null && now > data.nextRefresh){
                    refresh();
                }
            }
        };
        data.interval = setInterval(update, parseInt(dt * 1000));
        const result = await refresh();
        update();
        return result;
    };

    return {
        connect: async (dt=2, updateOnMinutes=5) => {
            data.updateOnMinutes = updateOnMinutes;
            return await runWatch(dt);
        },
        stop: () => {
            if (data.interval !== null) {
                clearInterval(data.interval);
                data.interval = null;
            }
        },
        ref: statusRef
    };
};