const messageTypes = {
    success: 'alert-success',
    error: 'alert-danger'
};

const sendMessage = (commit, msgType, message) => {
    let timeout = -1;
    let overlay = false;
    if (typeof(message) === 'object'){
        timeout = message.timeout;
        overlay = !!message.overlay;
        message = message.message;
    }
    commit('setMessage', {message, overlay, type: msgType});
    if (timeout > 0){
        setTimeout(()=>{
            commit('setMessage', {message: null, overlay: false, type: null});
        }, timeout * 1000);
    }
};

export const alert = {
    namespaced: true,
    state: {
        type: null,
        message: null,
        overlay: false
    },
    actions: {
        success({ commit }, message) {
            sendMessage(commit, messageTypes.success, message);
        },
        error({ commit }, message) {
            sendMessage(commit, messageTypes.error, message);
        },
        clear({ commit }) {
            commit('clear');
        }
    },
    mutations: {
        setMessage(state, {message, type, overlay}) {
            state.type = type;
            state.message = message;
            state.overlay = overlay;
        },
        clear(state) {
            state.type = null;
            state.message = null;
            state.overlay = false;
        }
    }
};