import { createRouter, createWebHistory } from 'vue-router';
import IndexView from '../views/IndexView.vue';
import HomeListView from '../views/HomeListView.vue';
import LoginView from '../views/LoginView.vue';
import {storage} from '../utils/storage';
import store from '../store';

const routes = [
    {
        path: '/',
        name: 'index',
        component: IndexView
    },
    {
        path: '/homes',
        name: 'homes',
        component: HomeListView
    },
    {
        path: '/home/:id',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
    },
    {
        path: '/home/:id/devices',
        name: 'devices',
        component: () => import(/* webpackChunkName: "devices" */ '../views/DevicesView.vue')
    },
    {
        path: '/home/:id/rules',
        name: 'rules',
        component: () => import(/* webpackChunkName: "rules" */ '../views/RulesView.vue')
    },
    {
        path: '/home/:id/counters',
        name: 'counters',
        component: () => import(/* webpackChunkName: "counters" */ '../views/CountersView.vue')
    },
    {
        path: '/home/:id/counters/reports',
        name: 'counter-reports',
        component: () => import(/* webpackChunkName: "counter_reports" */ '../views/CounterReportsView.vue')
    },
    {
        path: '/home/:id/statistics',
        name: 'statistics',
        component: () => import(/* webpackChunkName: "counter_reports" */ '../views/StatisticsView.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "about" */ '../views/ProfileView.vue')
    },
    {
        path: '/an2023',
        name: 'Alena',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/TempPage.vue')
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    store.dispatch('alert/clear');

    if (authRequired && !storage.user) {
        return next('/login');
    }
    next();
});

export default router;
