<template>
    <svg :width="w" :height="h" xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="xMinYMin">
        <use :xlink:href="`${url}#${iconId}`"></use>
    </svg>
</template>


<script>
    export default {
        name: 'SvgIcon',
        props: {
            type: {
                type: String,
                default: 'simple'
            },
            iconId: String,
            w: {
                type: Number,
                default: 20
            },
            h: {
                type: Number,
                default: 20
            }
        },
        setup(props) {
            const url = (()=>{
                switch (props.type) {
                    case 'time':
                        return require("@/assets/time-icons.svg");
                    default:
                        return require("@/assets/simple-icons.svg");
                }
            })();
            return {url};
        }
    };
</script>

<style lang="scss" scoped>
</style>