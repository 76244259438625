import * as api from '../utils/api';


export const devices = {
    namespaced: true,
    state: {
        controls: {},
        sensors: {},
        devices: {},
        state: {},
    },
    actions: {
        load({ commit, state }, {homeId, force = false}) {
            if (force || state.devices[homeId] === undefined){
                api.devices(homeId).then((resp) => {
                    commit('setDevices', {homeId, devices: resp.devices, iot: resp.iot});
                });
            }
            if (force || state.controls[homeId] === undefined){
                api.deviceControls(homeId).then((resp) => {
                    commit('setControls', {homeId, controls: resp.controls});
                });
            }
            if (force || state.sensors[homeId] === undefined){
                api.deviceSensors(homeId).then((resp) => {
                    commit('setSensors', {homeId, sensors: resp.sensors});
                });
            }
        },
        updateDeviceState({commit, state}, {homeId, address, deviceState}) {
            const dev = (state.devices[homeId] || []).find(d=>d.ieee_address == address);
            if (dev !== undefined)
                commit('updateDeviceState', {homeId, id: dev.id, deviceState});
            else
                console.warn(`Problem with device ${address}. State: ${deviceState}`);
        }
    },
    mutations: {
        setDevices(state, {homeId, devices, iot}) {
            const iotMap = iot.reduce((acc, b) => {acc[`${b.kind}_${b.id}`] = true; return acc;}, {});
            state.devices[homeId] = devices.map(d => {
                d.controls = d.controls.map(c => ({iot: iotMap[`control_${c.id}`] || false, ...c}));
                d.sensors = d.sensors.map(s => ({iot: iotMap[`sensor_${s.id}`] || false, ...s}));
                return d;
            });
        },
        setControls(state, {homeId, controls}) {
            state.controls[homeId] = controls;
        },
        setSensors(state, {homeId, sensors}) {
            state.sensors[homeId] = sensors;
        },
        updateDeviceState(state, {homeId, id, deviceState}){
            const currentState = (state.state[homeId] || {});
            currentState[id] = deviceState;
            state.state[homeId] = currentState;
        }
    },
    getters: {
        devices(state) {
            return (homeId) => state.devices[homeId];
        },
        controls(state) {
            return (homeId) => state.controls[homeId];
        },
        sensors(state) {
            return (homeId) => state.sensors[homeId];
        },
        deviceAddressMap(state) {
            return (homeId) => (state.devices[homeId] ? state.devices[homeId].reduce((acc, d)=>{
                acc[d.id] = d.friendly_name;
                return acc;
            }, {}) : null);
        },
        deviceState(state) {
            return (homeId, deviceId) => (state.state[homeId] || {})[deviceId];
        }
    }
};