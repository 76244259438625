<template>
    <div :class="['input', {withLabel: !hideLabel}]">
        <label v-if="!hideLabel">{{name}}</label>
        <input 
            :type="type"
            :name="name.toLowerCase()"
            :value="modelValue"
            @input="handleInput"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            @keydown.enter="$emit('onenter', $event.target.value)"
            @keydown.esc="$emit('onesc')"
        />
    </div>
</template>

<script>
    export default {
        name: 'InputField',
        props: {
            modelValue: String,
            type: String,
            name: String,
            transform: Function,
            hideLabel: {
                type: Boolean,
                default: false
            }
        },
        emits: ['update:modelValue', 'onesc', 'onenter', 'focus'],
        setup(props, {emit}) {
            const handleInput = (e)=>{
                const v = props.transform ? props.transform(e.target.value) : e.target.value;
                emit('update:modelValue', v);
            };
            return {handleInput};
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .input {
        width: 200px;
        max-width: 100%;
        &.with-label {
            margin-top: 10px;
        }
        label {
            display: block;
        }
        input {
            width: 100%;
            font-size: 20px;
            padding: 3px 7px;
            border: 3px #585858 solid;
            border-radius: 5px;
        }
    }
</style>