import * as api from '../utils/api';
import {storage} from '@/utils/storage';


const fetchCurrentView = () => {
    const hash = location.hash;
    if (hash && ["map", "list"].indexOf(hash.substring(1)) >= 0)
        return hash.substring(1);
    return storage.devicesView || 'map';
};


export const home = {
    namespaced: true,
    state: {
        loaded: false,
        homes: {},
        currentView: fetchCurrentView()
    },
    actions: {
        loadAll({ commit, state }, force = false) {
            if (force || Object.keys(state.homes).length == 0){
                api.homes().then(resp=>{
                    storage.homes = resp.items;
                    resp.items.forEach(h => {
                        commit('setHome', {id: h.id, home: h});
                    });
                    commit('setLoaded');
                });
            }
        },
        changeView({commit}, view) {
            storage.devicesView = view;
            commit('setView', view);
        }
    },
    mutations: {
        setHome(state, {id, home}) {
            state.homes[id] = home;
        },
        setLoaded(state) {
            state.loaded = true;
        },
        setView(state, view) {
            state.currentView = view;
        }
    },
    getters: {
        homes(state) {
            return Object.keys(state.homes).map(k => state.homes[k]);
        },
        home(state) {
            return (id) => state.homes[id];
        },
        isLoaded(state) {
            return state.loaded;
        },
        currentView(state) {
            return state.currentView;
        },
        oppositeView(state) {
            return {map: 'list', list: 'map'}[state.currentView];
        },
        permissions(state) {
            return (homeId) => {
                const home = state.homes[homeId];
                if (!home || !home.permissions)
                    return {};
                return home.permissions;
            };
        },
        hasPermission(state) {
            return (homeId, ...permission) => {
                const home = state.homes[homeId];
                if (!home || !home.permissions)
                    return false;
                if (home.permissions.is_admin)
                    return true;
                return permission.every(p => home.permissions[p]);
            };
        },
        customization(state) {
            return (homeId) => state.homes[homeId]?.customization;
        }
    }
};