import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import {clickOutside} from './utils/vueTools';

createApp(App).directive(
    'focus', {
        mounted(el) { el.focus(); }
    }
).directive(
    "click-outside", clickOutside
).use(store).use(router).mount('#app');
