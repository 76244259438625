import { createStore } from 'vuex';

import { alert } from './alert';
import { auth } from './auth';
import { counters } from './counters';
import { home } from './home';
import { devices } from './devices';

export default createStore({
    modules: {
        alert,
        auth,
        counters,
        home,
        devices
    }
});