<template>
    <div :class="['homes', {loading}]">
        <div
            class="home"
            v-for="home in homes"
            v-bind:key="home.id"
            @click="()=>openHome(home)"
        >
            <div class="home-name">{{home.name}}</div>
            <div>Token: {{home.token}}; ID#{{home.id}}</div>
            <div v-if="home.address">{{home.address}}</div>
            <svg-icon
                v-if="homes.length > 1"
                iconId="star"
                :class="['star', {active: home.is_default}]"
                @click.stop="setDefault(home.id)"
            />
        </div>
        <Button @click="addHome" text="Add home" />
    </div>
</template>

<script setup>
    import {computed, ref} from 'vue';
    import {useRouter} from 'vue-router';
    import {useStore} from 'vuex';
    import * as api from '@/utils/api';
    import Button from '@/components/Button.vue';
    import SvgIcon from '@/components/SvgIcon.vue';

    const router = useRouter();
    const store = useStore();
    const loading = ref(false);
    store.dispatch('home/loadAll');
    const homes = computed(()=>store.getters['home/homes']);

    const openHome = (home)=>{
        router.push({name: 'home', params: {id: home.id}});
    };

    const addHome = () => {
        const name = prompt("Enter home name:");
        api.addHome(name).then(()=>{
            location.reload();
        });
    };

    const setDefault = (homeId) => {
        loading.value = true;
        api.profileEdit({default_home: homeId}).then(() => {
            store.dispatch('home/loadAll', true);
            loading.value = false;
        });
    };
</script>

<style lang="scss" scoped>
    @import '../utils/mixins';
    .homes {
        .home {
            @include card();
            position: relative;
            margin: 5px;
            cursor: pointer;
            &:hover {
                background-color: #f7f7f7;
            }
            &-name {
                font-weight: 700;
                font-size: 22px;
            }
            .star {
                position: absolute;
                right: 12px;
                top: 12px;
                color: #ddd;
                transition: all 0.2s ease-in-out;
                &:hover {
                    color: #333;
                    transform: scale(1.1);
                }
                &.active {
                    color: #dfb233;
                }
            }
        }
        button {
            margin: 5px 5px 50px;
        }
        &.loading {
            .star {
                animation: 1s infinite rotate;
            }
        }
    }

    @keyframes rotate {
        from {rotate: 0;}
        to {rotate: 72deg;}
    }
</style>
