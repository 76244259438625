import * as api from '../utils/api';
import router from '../router';
import { storage } from '../utils/storage';

const user = storage.user;
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null };


export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, { username, password }) {
            commit('loginRequest', { username });

            api.login({username, password})
                .then(
                    user => {
                        commit('loginSuccess', user);
                        router.push('/');
                    },
                    error => {
                        commit('loginFailure', error);
                    }
                );
        },
        logout({ commit }) {
            api.logout();
            commit('logout');
        }
    },
    mutations: {
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
        }
    },
    getters: {
        loggedIn(state) {
            return state.status.loggedIn;
        }
    }
};