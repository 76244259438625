<template>
    <nav class="header">
        <router-link :to="headLine.link" class="home-link">
            <hub-status v-if="homeId" :homeId="homeId" />
            {{headLine.text}}
        </router-link>
        <div class="header-links">
            <router-link v-if="countersLink" :to="countersLink">
                <svg-icon iconId="meter"/>
            </router-link>
            <router-link v-if="rulesLink" :to="rulesLink">
                <svg-icon iconId="signpost"/>
            </router-link>
            <div v-if="deviceOppositeView" class="clickable" @click="switchView(deviceOppositeView)">
                <svg-icon :iconId="deviceOppositeView == 'map' ? 'map' : 'list'" />
            </div>
        </div>
        <div class="profile" @click="showMenu=!showMenu" v-click-outside="()=>{showMenu=false}">
            <svg-icon iconId="user"/>
            <div class="menu" v-if="showMenu">
                <ul>
                    <li>
                        <router-link to="/profile">
                            <svg-icon iconId="user"/> Profile
                        </router-link>
                    </li>
                    <li v-if="counterReportsLink">
                        <router-link :to="counterReportsLink">
                            <svg-icon iconId="report"/> Counter reports
                        </router-link>
                    </li>
                    <li v-if="devicesLink">
                        <router-link :to="devicesLink">
                            <svg-icon iconId="plug"/> Devices
                        </router-link>
                    </li>
                    <li v-if="logsLink">
                        <router-link :to="logsLink">
                            <span class="no-icon" /> Logs
                        </router-link>
                    </li>
                    <li v-if="showIndexLink">
                        <router-link to="/homes">
                            <span class="no-icon" /> Homes
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    import {ref, computed} from 'vue';
    import {useRoute, useRouter} from 'vue-router';
    import {useStore} from 'vuex';
    import {perm} from '@/utils/permission';
    import HubStatus from './HubStatus.vue';
    import SvgIcon from '@/components/SvgIcon.vue';

    export default {
        name: 'HeaderSH',
        props: {
        },
        setup() {
            const route = useRoute();
            const router = useRouter();
            const store = useStore();

            if (store.getters['auth/loggedIn']){
                store.dispatch('home/loadAll');
            }

            const inHomeLink = (pageName, ...permissions) => {
                return computed(() => {
                    if (route.params.id && route.name !== pageName && perm(route.params.id, ...permissions))
                        return {name: pageName, params: route.params};
                    return null;
                });
            };

            const showMenu = ref(false);

            const page = computed(()=>route.name);
            const homeId = computed(()=>route.params.id);
            const countersLink = inHomeLink('counters');
            const counterReportsLink = inHomeLink('counter-reports', 'view_counter_reports');
            const devicesLink = inHomeLink('devices');
            const logsLink = inHomeLink('statistics');
            const rulesLink = inHomeLink('rules');

            const showIndexLink = computed(()=>{
                return (route.name !== 'index');
            });
            const headLine = computed(()=>{
                const homeId_ = route.params.id;
                if (!homeId_){
                    return {
                        text: 'Home',
                        link: {name: 'index'}
                    };
                }
                const home = store.getters['home/home'](homeId_);
                if (!home)
                    return {text: 'Loading...', link: ''};
                return {
                    text: home.name, 
                    link: {name: 'home', params: {id: home.id}}
                };
            });
            const deviceOppositeView = computed(() => {
                if (route.name == 'home')
                    return store.getters['home/oppositeView'];
                return null;
            });

            const switchView = (view) => {
                router.replace({hash: '#' + view});
                store.dispatch('home/changeView', view);
            };
            
            return {
                page, headLine, homeId, countersLink, showMenu, showIndexLink,
                deviceOppositeView, switchView, counterReportsLink, devicesLink,
                rulesLink, logsLink
            };
        },
        components: {HubStatus, SvgIcon},
    };
</script>

<style lang="scss" scoped>
    @import "@/utils/mixins";
    $primary: #08ff6b;
    $primary-dark: #22a769;
    .header {
        width: 100%;
        height: 64px;
        background-color: #3c3c3c;
        color: white;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &-links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 35px;
            margin-right: 35px;
        }
        a, .clickable {
            color: white;
            font-weight: 500;
            cursor: pointer;
            text-decoration: none;
            @include desktopHover {
                color: $primary;
            }
        }
        .home-link {
            display: flex;
            flex-grow: 1;
            flex-direction: row;
            align-items: center;
            gap: 20px;
        }
        .profile {
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            @include desktopHover {
                color: $primary;
            }
            .menu {
                position: absolute;
                z-index: 10;
                right: 20px;
                margin-top: 10px;
                @include card();
                background-color: white;
                color: black;
                svg {
                    margin-bottom:-3px;
                }
                .no-icon {
                    display: inline-block;
                    width: 20px;
                }
                a, .clickable {
                    color: black;
                    cursor: pointer;
                    font-weight: 500;
                    @include desktopHover {
                        color: $primary-dark;
                    }
                }
                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    li {
                        padding: 5px 0;
                    }
                }
            }
        }
    }
</style>
