<template>
    <div :class="['index', {active}]">
        <h3>Smarthome</h3>
    </div>
</template>

<script setup>
    // @ is an alias to /src
    import {computed, watch, ref} from 'vue';
    import {useRouter} from 'vue-router';
    import {useStore} from 'vuex';

    const router = useRouter();
    const store = useStore();
    const active = ref(false);

    store.dispatch('home/loadAll');
    const homesLoaded = computed(()=>store.getters['home/isLoaded']);
    const homes = computed(()=>store.getters['home/homes']);

    const openHome = (home)=>{
        router.push({name: 'home', params: {id: home.id}});
    };
    
    const tryToRedirect = () => {
        if (homesLoaded.value){
            if (homes.value.length){
                if (homes.value.length == 1)
                    return openHome(homes.value[0]);
                const defaultHome = homes.value.find(x => x.is_default);
                if (defaultHome)
                    return openHome(defaultHome);
            }
            router.push({name: 'homes'});
        }
    };

    watch(() => homes.value, tryToRedirect);
    tryToRedirect();

    setTimeout(() => {
        active.value = true;
    }, 2000);

</script>

<style lang="scss" scoped>
    @import '../utils/mixins';
    .index {
        height: calc(100vh - 64px);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 900;
        color: white;
        background: linear-gradient(45deg, #185b7c, #3cc2d6);
        opacity: 0;
        transition: opacity 2s ease-in-out;
        &.active {
            opacity: 1;
        }
    }
    @keyframes appear {
        from {opacity: 0;}
        to {opacity: 1;}
    }
</style>
