import { authHeader, getToken, getHomeToken } from "./auth";
import { config } from "../config";
import { storage } from "../utils/storage";
import store from '../store';

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
                location.reload(true);
            }

            const error = (data && (data.detail || data.reason)) || response.statusText;
            return Promise.reject(error);
        }
        if (data.status !== 'OK')
            Promise.reject(data.reason);
        return data;
    });
};

const request = ({url, method, data, params}) => {
    return new Promise((resolve, reject) => {
        const host = config.host;
        const options = {
            method: method.toUpperCase(),
            headers: { 'Content-Type': 'application/json', ...authHeader() }
        };
        if (['post', 'put'].indexOf(method.toLowerCase()) >= 0)
            options.body = JSON.stringify(data);
        const url_ = new URL(`${config.secure ? 'https' : 'http'}://${host}${url}`);
        if (params)
            Object.keys(params).forEach(
                key => url_.searchParams.append(key, params[key])
            );
        fetch(url_, options)
            .then(handleResponse)
            .then(resp=>resolve(resp))
            .catch(err=>{
                store.dispatch('alert/error', err, { root: true });
                reject(err);
            });
    });
};

export const websocket = (path, params) => {
    params = params || {};
    params.token = getToken();
    const query = Object.keys(params).map((paramKey) => {
        return `${paramKey}=${params[paramKey]}`;
    }).join('&');
    let ws = null;
    const makeWebsocket = () => {
        const res = new WebSocket(`${config.secure ? 'wss' : 'ws'}://${config.host}${path}?${query}`);
        res.onmessage = function(event) {
            const message = event.data;
            handlers.forEach(h => h(message));
        };
        res.onclose = function(e) {
            console.log('Socket is closed. Reconnecting...', e.reason);
                setTimeout(function() {
                ws = makeWebsocket();
            }, 500);
        };
        return res;
    };
    const handlers = [];
    ws = makeWebsocket();
    const wsWorker = {
        onmessage: (handler) => {
            handlers.push(handler);
            return wsWorker;
        },
        send: (data) => {
            ws.send(JSON.stringify(data));
        },
        getReadyState: () => ws.readyState
    };
    return wsWorker;
};

const get = ({url, params}) => request({url, method: 'get', params});
const delete_ = ({url, params}) => request({url, method: 'delete', params});

const post = ({url, data, params}) => request(
    {url, method: 'post', data, params}
);

const put = ({url, data, params}) => request(
    {url, method: 'put', data, params}
);

export const login = ({username, password}) => {
    return post({url: '/auth/login', data: {name: username, password}}).then(user=>{
        if (user && user.token){
            storage.user = user;
            return user;
        }
    });
};

export const logout = ()=>{localStorage.removeItem('user');};

export const homes = () => get({url: '/home/list'});
export const addHome = (name) => post({url: '/home/list', data: {name}});
export const home = (homeId) => get({url: `/home/${homeId}`});

export const addMap = (homeId, map) => post({url: `/home/${homeId}/map`, data: map});
export const changeMap = (homeId, mapId, map) => put({url: `/home/${homeId}/map/${mapId}`, data: map});

export const counterAlgorithms = () => get({url: `/home/0/counters/algorithms`});
export const counters = (homeId) => get({url: `/home/${homeId}/counters`});
export const countersSettings = (homeId) => get({url: `/home/${homeId}/counters/settings`});
export const setCountersSettings = (homeId, settings) => post({url: `/home/${homeId}/counters/settings`, data: settings});
export const counterPlots = (homeId, days=30) => get({url: `/home/${homeId}/counters/plot?days=${days}`});
export const counterReports = (homeId) => get({url: `/home/${homeId}/counters/reports`});
export const setCounter = (homeId, counterId, value = 0) => {
    return post({
        url: `/internal/counters`, 
        params: {home_id: homeId, token: getHomeToken(homeId)},
        data: {counter_id: counterId, value}
    });
};

export const devices = (homeId) => get({url: `/home/${homeId}/devices/list`});
export const updateDevice = (homeId, data) => put({url: `/home/${homeId}/devices/list`, data});

export const deviceControls = (homeId) => get({url: `/home/${homeId}/devices/controls`});
export const updateDeviceControl = (homeId, data) => put({url: `/home/${homeId}/devices/controls`, data});
export const deviceSensors = (homeId) => get({url: `/home/${homeId}/devices/sensors`});
export const updateDeviceSensor = (homeId, data) => put({url: `/home/${homeId}/devices/sensors`, data});
export const sensorStat = (homeId, sensorId, hours=0) => get({url: `/home/${homeId}/devices/sensor_stat/${sensorId}?hours=${hours}`});
export const markIOT = (homeId, data) => put({url: `/home/${homeId}/devices/iot`, data});

export const rules = (homeId) => get({url: `/home/${homeId}/devices/rules?all=1`});
export const addRule = (homeId, data) => post({url: `/home/${homeId}/devices/rules`, data});
export const removeRule = (homeId, ruleId) => delete_({url: `/home/${homeId}/devices/rules/${ruleId}`});
export const editRule = (homeId, ruleId, data) => put({url: `/home/${homeId}/devices/rules/${ruleId}`, data});
export const toggleRule = (homeId, ruleId) => put({url: `/home/${homeId}/devices/rules/${ruleId}/toggle`});
export const runRule = (homeId, ruleId) => post({url: `/home/${homeId}/devices/rules/${ruleId}`});

export const statistics = (homeId, {skip=0, limit=100, kinds=[]}) => 
    get({url: `/home/${homeId}/statistics?skip=${skip}&limit=${limit}&kinds=${kinds.join(',')}`});

export const profile = () => get({url: '/profile/settings'});
export const profileEdit = (data) => post({url: '/profile/settings', data});
