<template>
    <div>
        <Header />
        <div
            v-if="alert.message"
            :class="['alert', alert.type, {overlay: alert.overlay}]"
        >{{alert.message}}</div>
        <router-view/>
    </div>
</template>

<script>
    import {computed} from 'vue';
    import {useStore} from 'vuex';
    import Header from './views/components/header/Header.vue';
    export default {
        components: { Header },
        setup() {
            const store = useStore();
            const alert = computed(()=>store.state.alert);
            window.addEventListener('online', () => {
                store.dispatch('alert/success', {message: "You are back online", timeout: 3}, { root: true });
            });
            window.addEventListener('offline', () => {
                store.dispatch('alert/error', {message: "You are offline"}, { root: true });
            });
            return {alert};
        },
    };
</script>


<style lang="scss">
    #app {
        font-family: 'Montserrat', Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    body, * {
        box-sizing: border-box;
        margin: 0;
    }

    body {
        min-height: 100vh;
    }

    nav {
        padding: 30px;
        a {
            font-weight: bold;
            color: #2c3e50;
            &.router-link-exact-active {
                color: #42b983;
            }
        }
    }

    .alert {
        padding: 15px;
        border-radius: 5px;
        margin: 15px;
        position: absolute;
        z-index: 1;
        right: 0px;
        &.alert-danger { background-color: #ffcece; }
        &.alert-success { background-color: #90e17c; }
        &.overlay {
            z-index: 2;
            position: fixed;
            right: 0;
            top: 18px;
        }
    }
</style>
