import { storage } from './storage';

export function getToken() {
    // return authorization header with jwt token
    let user = storage.user;
    if (user && user.token) {
        return user.token;
    } else {
        return null;
    }
}

export function authHeader() {
    // return authorization header with jwt token
    let user = storage.user;
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}

export function getHomeToken(homeId) {
    const homes = storage.homes;
    const home = homes.find(h => h.id == homeId);
    if (home)
        return home.token;
    return '';
}