<template>
    <div 
        :class="`light state-${status.state}`"
    >
        <div class="hint">
            {{status.text}}
        </div>
    </div>
</template>


<script>
    import {hubStatus} from "../../helpers/hubStatus";
    export default {
        name: 'HubStatus',
        props: {
            homeId: String
        },
        setup(props) {
            const hubWorker = hubStatus(props.homeId);
            const status = hubWorker.ref;
            hubWorker.connect(2, 4);
            return {status};
        }
    };
</script>

<style lang="scss" scoped>
    @import "@/utils/mixins";
    .light {
        @include circle(20px);
        background-color: #999;
        &.state-1 {background-color: #28e169;}
        &.state-2 {background-color: #f1bd00;}
        &.state-3 {background-color: #f33636;}
        .hint {
            position: absolute;
            z-index: 2;
            margin-top: 30px;
            @include card(20px);
            display: none;
            background-color: white;
            color: black;
        }
        &:hover {
            .hint {
                display: block;
            }
        }
    }
</style>