<template>
    <button :class="['button', kind, `color-${color}`, `size-${size}`, {square}]" type="button">
        <svg-icon :iconId="iconId" v-if="iconId" :w="iconSize" :h="iconSize" />
        {{text}}
    </button>
</template>


<script>
    import {ref, computed} from 'vue';
    import SvgIcon from './SvgIcon.vue';

    export default {
        components: { SvgIcon },
        name: 'ButtonSH',
        props: {
            text: String,
            kind: {
                type: String,
                default: 'normal'
            },
            color: {
                type: String,
                default: 'accent'
            },
            size: {
                type: String,
                default: 'm'
            },
            square: {
                type: Boolean,
                default: false
            },
            iconSize: {
                type: Number,
                default: 20
            }
        },
        setup(props) {
            const value = ref(props.initialValue);
            const iconId = computed(()=>{
                const res = {
                    edit: 'edit',
                    accept: 'check',
                    cancel: 'cross',
                    settings: 'gear',
                    delete: 'cross',
                    draw: 'brush',
                }[props.kind];
                return res ? res : props.square ? props.kind : undefined;
            });
            return {value, iconId};
        }
    };
</script>

<style lang="scss" scoped>
    @import "@/utils/variables.scss";
    %btn {
        width: 30px;
        height: 30px;
        padding: 0;
        margin: -6px 0;
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border-radius: 5px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
            transform: scale(1.1);
        }
    }
    @mixin btn-color($main, $shadow, $text: white){
        background-color: $main;
        color: $text;
        box-shadow: 0 4px 0 $shadow;
        &:hover {
            box-shadow: 0 8px 5px rgba($shadow, 0.8);
        }
    }

    @mixin icon-color($main, $shadow, $text: black){
        @extend %btn;
        background-color: $main;
        color: $text;
        box-shadow: 0 2px 0 $shadow;
        &:hover {
            box-shadow: 0 8px 5px rgba($shadow, 0.8);
        }
    }

    .button {
        border: none;
        font-size: 1.2em;
        padding: 9px 20px;
        border-radius: 5px;
        transition: all 0.2s ease-in-out;
        &.square {
            padding: 9px;
            svg {
                display: block;
            }
        }
        &:hover {
            transform: translateY(-2px);
        }
        &.color-accent {
            @include btn-color(#50a524, #224a0d);
        }
        &.color-gray {
            @include btn-color(#595959, #000);
        }
        &.color-danger {
            @include btn-color(#ee4646, #a71919);
        }

        &.size-s {
            padding: 4px 9px;
            font-size: 1em;
            margin-bottom: 2px;
            vertical-align: bottom;
            &.square {
                padding: 4px;
            }
        }
        
        &.edit {
            @include icon-color(#ffc800, #4a4503);
        }
        &.accept {
            @include icon-color(#24eb62, #167e36);
        }
        &.delete {
            @include icon-color(#ee4646, #a71919);
        }
        &.cancel {
            @include icon-color(#ababab, #5c5c5c);
        }
        &.draw {
            @include icon-color(#ababab, #5c5c5c);
        }
        &.settings {
            @include icon-color(#ababab, #5c5c5c);
        }
    }
</style>