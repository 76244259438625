const baseStorage = {};

export const storage = new Proxy(baseStorage, {
    get(target, name, receiver) {
        if (!Reflect.has(target, name)) {
            const v = localStorage.getItem(name);
            if (v === null){
                console.log("Value '" + name + "' not set");
                return undefined;
            }
            baseStorage[name] = JSON.parse(v).value;
        }
        return Reflect.get(target, name, receiver);
    },
    set(target, name, value, receiver) {
        localStorage.setItem(name, JSON.stringify({value}));
        return Reflect.set(target, name, value, receiver);
    }
});