<template>
    <div class="root">
        <h1>Login</h1>
        <InputField name="Username" type="text" v-model="username" />
        <InputField name="Password" type="password" v-model="password" />
        <Button class="login-button" text="Login" @click="submit()"/>
    </div>
</template>

<script>
    import InputField from "../components/InputField.vue";
    import Button from "../components/Button.vue";
    import {ref} from "vue";
    import store from "../store";

    export default {
        name: 'LoginView',
        components: {
            InputField, Button
        },
        setup(){
            const username = ref('');
            const password = ref('');
            const submit = ()=>{
                const cred = {
                    username: username.value,
                    password: password.value
                };
                store.dispatch('auth/login', cred);
            };
            return {submit, username, password};
        }
    };
</script>

<style scoped lang="scss">
    .root {
        padding: 30px;
        .login-button {
            margin-top: 30px;
        }
    }
</style>